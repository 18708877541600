<template>
  <div>
		<div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/recordOmissions' }">漏检任务</el-breadcrumb-item>
        <el-breadcrumb-item>漏检任务设备详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
		<div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <div class="form-title">巡检信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
						<el-form-item label="巡检代号：" prop="name">{{ form.patrolNumber }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡检点名称：" prop="name">{{ form.patrolName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设施位置：" prop="name">{{ form.facilityName }}</el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item label="巡视点编号：" prop="name">{{ form.patrolNumber }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
						<el-form-item label="检查人员" prop="name">{{ form.staffNames }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡检点名称：" prop="name">{{ form.patrolName }}</el-form-item>
          </el-col>
        </el-row>
				<div class="form-title">设备信息</div>
				<el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备名称：" prop="name">{{ form.deviceVO.archivesName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格参数：" prop="name">{{ form.deviceVO.specification }}</el-form-item>
          </el-col>
        </el-row>
				<el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属部门：" prop="name">{{ form.deviceVO.departmentName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备类型：" prop="name">{{ form.deviceVO.deviceTypeName }}</el-form-item>
          </el-col>
        </el-row>
				<el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备别名：" prop="name">{{ form.deviceVO.alias }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="投入日期：" prop="name">{{ form.deviceVO.applyTime ? dayjs(form.deviceVO.applyTime).format('YYYY-MM-DD HH:mm:ss'):'' }}</el-form-item>
          </el-col>
        </el-row>
        <div class="form-title">检查内容</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item v-for="(item, i) in form.deviceContent" :key="i" :label="i + 1 +'.检查内容：'">{{ item.checkContent }}</el-form-item>
            </el-col>
          </el-row>
        <div class="form-red-title">该设备已漏检</div>
        <!-- <div class="form-title">巡检点图片文件</div>
        <div >
          <img v-for="(item, index) in form.imageUrls" :key="index" :src="item" class="file-img">
        </div> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      form: {
        deviceVO: {}
      },
			row: {},
			data: {}
    };
	},
	methods: {
    dayjs,
		loadData() {
      this.$ajax.post("recordTaskDevice", {
        archivesId: this.row.archivesId,
        patrolDeviceId: this.row.patrolDeviceId,
        patrolId: this.data.patrolId,
        taskDeviceId: this.row.taskDeviceId,
        taskId: this.data.taskId
      }).then((res) => {
        this.form = res.data
      });
    },
	},
	mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/recordOmissions')
		} else {
			this.row = this.$route.params.row
			this.data = this.$route.params.data
    }
    this.loadData()
  },
}
</script>

<style lang="less" scoped>
.file-img {
  width: 200px;
  height: 200px;
  margin-right: 10px;
}
</style>